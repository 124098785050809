import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Index = () => {
    let location = useLocation();

    const [comment, setComment] = useState("");
    const [replycontent, setreplycontent] = useState("");
    const [username, setusername] = useState("");
    const [appName, setappName] = useState("");
    const [device, setdevice] = useState("");
    const [appVersion, setappVersion] = useState("");
    const [score, setscore] = useState("");
    const [time, settime] = useState("");
    const [queryInfo, setqueryInfo] = useState({});
    const [show, setShow] = useState(true);

    const parse = (url) => {
        // 通过 decodeURIComponent 来转义字符
        // 夹杂在 ? 与 # 之前的字符及时 qs，使用 /\?([^/?#:]+)#?/ 正则来抽取
        // 使用正则从 URL 中解析出 querystring
        // 通过 Optional Chain 来避免空值错误
        const queryString =
            decodeURIComponent(url).match(/\?([^/?#:]+)#?/)?.[1];
        if (!queryString) {
            return {};
        }

        let queryObj = queryString.split("&").reduce((params, block) => {
            // 如果未赋值，则默认为空字符串
            const [k, v = ""] = block.split("=");
            if (params[k] !== undefined) {
                // 处理 key 出现多次的情况，设置为数组
                params[k] = [].concat(params[k], v);
            } else {
                params[k] = v;
            }
            return params;
        }, {});
        return queryObj;
    };

    useEffect(() => {
        let query = parse(location.search);
        setqueryInfo(parse(location.search));
        axios
            .get(
                `https://ad.novelmanga.com/comments/reply?reviewid=${query.reviewid}&packagename=${query.packagename}`
            )
            .then((res) => {
                console.log(res);
                setusername(res.data.username);
                //   this.username = res.data.username
                setappName(res.data.app_name);
                //   this.score = res.data.score
                setscore(res.data.score);
                //   this.time = res.data.time
                settime(res.data.time);

                // this.device = res.data.device;
                setdevice(res.data.device);
                // this.appVersion = res.data.app_version;
                setappVersion(res.data.app_version);

                // this.comment = res.data.comment;
                setComment(res.data.comment);
                // this.replycontent = res.data.reply_content;
                setreplycontent(res.data.reply_content);

                if (res.data.reply_content && res.data.reply_content !== "") {
                    // this.show = false;
                    setShow(false);
                } else {
                    // this.show = true;
                    setShow(true);
                }
            });
    }, []);

    const setTextareaData=(value)=>{
        setreplycontent(value)
    }
    const sub = () => {
        const formData = new FormData();
        formData.append("reviewid", queryInfo.reviewid);
        formData.append("packagename", queryInfo.packagename);
        formData.append("comment", queryInfo.comment);
        formData.append("replycontent", replycontent);
        axios
            .post("https://ad.novelmanga.com/comments/reply", formData)
            .then((res) => {
                console.log(res);
                  window.location.reload();
            });
    };

    return (
        <div className="myreply">
            <div className="reply">
                <div>
                    <span className="label">用户名称：</span>
                    <span className="l1">{username}</span>
                </div>
                <div>
                    <span className="label">评论内容：</span>
                    <span className="l2">{comment}</span>
                </div>
                <div>
                    <span className="label">用户评分:</span>
                    <span>{score}分</span>
                </div>
                <div>
                    <span className="label">评论时间：</span>
                    <span>{time}</span>
                </div>
                <div>
                    <span className="label">应用名称：</span>
                    <span>{appName}</span>
                </div>
                <div>
                    <span className="label">版本信息：</span>
                    <span>{appVersion}</span>
                </div>
                <div>
                    <span className="label">设备信息：</span>
                    <span>{device}</span>
                </div>
                <div className="reflex">
                    <span className="label">回复信息：</span>
                    <textarea
                        value={replycontent}
                       onChange={(e)=>setTextareaData(e.target.value)}
                    ></textarea>
                </div>
                {show ? (
                    <button className="sub" onClick={() => sub()}>
                        发送
                    </button>
                ) : null}

                {/* <!-- <div className="toast">发送成功</div> --> */}
            </div>
        </div>
    );
};

export default Index;
