import React,{useEffect} from "react";
import Header from "@/components/Header.jsx";
import Footer from "../components/Footer";

const Page = (props) => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <div className="page pt102">
            <Header />
            <div className="wrapper w1200 pb140">
                <div className="title fz50 pt100 text-white ffab">
                PRIVACY POLICY
                </div>
                <div className="wrap mt40 grey">
                    <div className="_imgbox_1wz23_1">
                        <div className="fz24 fw500 ffab" data-letter="nottop">
                            1. INTRODUCTION
                        </div>
                        <div className="fz16">
                            1.1 This is the Privacy Policy of TAP-FUN Singapore
                            Pte. Ltd. (hereafter referred to as “
                            <span className="fw500">TAP-FUN</span>”, “
                            <span className="fw500">us</span>” or “
                            <span className="fw500">we</span>”) (this “
                            <span className="fw500">
                                Privacy Policy
                            </span>
                            ”).
                        </div>
                        <div className="fz16">

                            1.2 This Privacy Policy sets out how we collect,
                            use, disclose or otherwise process your personal
                            data in accordance with the Personal Data Protection
                            Act 2012 of Singapore (“
                            <span className="fw500">PDPA</span> ”). We
                            take our obligations under the PDPA seriously and
                            are committed to respecting the privacy rights of
                            all users of our games, including but not limited to
                            Race On (our “
                            <span className="fw500">Games</span>”) and
                            website (our “
                            <span className="fw500">Site</span>“)
                            (referred to collectively as our “
                            <span className="fw500">Services</span>“).
                        </div>
                        <div className="fz16">

                            1.3 Capitalised terms used in this Privacy Policy
                            that are not otherwise defined have the meanings
                            given to them in the TAP-FUN User Agreement.
                        </div>
                        <div className="fz16">

                            1.4 The term “
                            <span className="fw500">
                                personal data
                            </span>
                            ” means, in accordance with the PDPA, data, whether
                            true or not, about an individual who can be
                            identified from that data, or from that data and
                            other information to which an organisation has or is
                            likely to have access.
                        </div>
                        <div className="fz16">

                            1.5 By using the Services, registering for an
                            account with us, visiting our Site or providing your
                            personal data to us, you agree to accept the terms
                            outlined in this Privacy Policy, and you hereby
                            consent to us collecting, using, disclosing and/or
                            processing your personal data in accordance with
                            this Privacy Policy.
                        </div>
                        <div className="fz16">

                            1.6 You acknowledge that we may, from time to time,
                            modify, amend or otherwise update this Privacy
                            Policy without prior notification. Your continued
                            use of our Services shall constitute your continued
                            consent to our collection, use, disclosure and
                            processing or your personal data in accordance with
                            any updated Privacy Policy. We therefore advise you
                            to check this Privacy Policy for updates and before
                            using the Site.
                        </div>
                        <div className="fz16" data-letter="nottop">

                            1.7 IF YOU DO NOT CONSENT TO OUR COLLECTION, USE,
                            DISCLOSURE AND PROCESSING OF YOUR PERSONAL DATA AS
                            DESCRIBED HEREIN, PLEASE DO NOT ACCESS OR USE OUR
                            SERVICES.
                        </div>
                        <div className="fz24 fw500 ffab">
                            2. PERSONAL DATA WE COLLECT
                        </div>
                        <div className="fz16">

                            2.1 The personal data we collect includes, but is
                            not limited to:
                        </div>
                        <div className="fz16">

                            2.1.1 personal information including names, date of
                            birth and gender;
                        </div>
                        <div className="fz16">

                            2.1.2 contact information including email addresses,
                            residential addresses and phone numbers;
                        </div>
                        <div className="fz16">

                            2.1.3 payment information including billing
                            addresses, bank account information and payment
                            information;
                        </div>
                        <div className="fz16">

                            2.1.4 information in relation to our Services
                            including passwords, avatars, nicknames, comments
                            and how users use our Services
                        </div>
                        <div className="fz16">

                            2.1.5 information sent by or associated with the
                            device(s) used to access our Services including
                            hardware models, operating system version numbers,
                            International Mobile Equipment Identity (IMEI),
                            unique device identifiers, network device hardware
                            addresses (MAC), IP addresses, software version
                            numbers, network access methods and types and
                            operation logs;
                        </div>
                        <div className="fz16">

                            2.1.6 personal information provided to us by the
                            users of our Services;
                        </div>
                        <div className="fz16">

                            2.1.7 personal information required to be collected
                            in accordance with the relevant laws and
                            regulations; and
                        </div>
                        <div className="fz16">

                            2.1.8 aggregate data on content users engage with.
                        </div>
                        <div className="fz16">

                            2.2 The situations in which we may collect your
                            personal data, including the personal data listed in
                            Clause 2.1 above, includes, but is not limited to:
                        </div>
                        <div className="fz16">

                            2.2.1 when you register and create accounts for our
                            Games and related services;
                        </div>
                        <div className="fz16">

                            2.2.2 when you provide identity verification
                            documentations;
                        </div>
                        <div className="fz16">

                            2.2.3 when you enter into any agreement or provide
                            other documentation or information in respect of
                            your interactions with us, or when you use our
                            Services or products;
                        </div>
                        <div className="fz16">

                            2.2.4 when you interact with us, such as via
                            telephone calls (which may be recorded), letters,
                            fax, face-to-face meetings, social media platforms
                            and emails;
                        </div>
                        <div className="fz16">

                            2.2.5 when you use our electronic services or
                            interact with us via our Services, including,
                            without limitation, through cookies which we may
                            deploy when you interact with our Services;
                        </div>
                        <div className="fz16">

                            2.2.6 when you carry out any transactions through
                            our Services;
                        </div>
                        <div className="fz16">

                            2.2.7 when you provide us with feedback;
                        </div>
                        <div className="fz16">

                            2.2.8 when you register for our contests, games or
                            other events;
                        </div>
                        <div className="fz16">

                            2.2.9 when playing our Games or using our Services
                            in general; and
                        </div>
                        <div className="fz16">

                            2.3 The above does not purport to be exhaustive and
                            sets out some common types of personal data
                            collected and instances of when your personal data
                            may be collected.s
                        </div>
                        <div className="fz16">

                            2.4 You agree not to submit information which is
                            inaccurate or misleading, and you agree to inform us
                            of any inaccuracies or changes to such information
                            accordingly. We reserve the right, at our sole
                            discretion, to require from you further
                            documentation to verify the information provided by
                            you.
                        </div>
                        <div className="fz16">
                            2.5 Note that by registering an account for our
                            Services by using your social media account (“
                            <span className="fw500">
                                Social Media Account
                            </span>
                            ”), linking your account on our Services to your
                            Social Media Account or using any of our social
                            media features provided in our Services, you consent
                            to us accessing, collecting, using, disclosing or
                            processing your personal data from your Social Media
                            Account in accordance with such your Social Media
                            Account provider’s policies and this Privacy Policy.
                        </div>
                        <div className="fz16">

                            2.6 If you do not want us to collect the
                            aforementioned information or personal data, you may
                            opt out at any time by notifying our Data Protection
                            Officer in accordance with Clause 13 below. Note,
                            however, that opting out or withdrawing your consent
                            for us to collect, use or process your personal data
                            may affect your use and access of the Services.
                        </div>
                        <div className="fz24 fw500 ffab">
                            3. COLLECTION OF OTHER DATA
                        </div>
                        <div className="fz16">

                            3.1 As with most websites and mobile applications,
                            your device sends information which may include data
                            about you that gets logged by a web server when you
                            use our Services. This typically includes without
                            limitation your device’s Internet Protocol (IP)
                            address, computer/mobile device operating system and
                            browser type, type of mobile device, the
                            characteristics of the mobile device, the unique
                            device identifier (UDID) or mobile equipment
                            identifier (MEID) for your mobile device, the
                            address of a referring web site (if any), the pages
                            you visit on our website and mobile applications and
                            the times of visit, and sometimes a “cookie” (which
                            can be disabled using your browser preferences) to
                            help the site remember your last visit. If you are
                            logged in, this information is associated with your
                            personal account. The information is also included
                            in anonymous statistics to allow us to understand
                            how visitors use our Services.
                        </div>
                        <div className="fz16">

                            3.2 Our mobile applications may collect precise
                            information about the location of your mobile device
                            using technologies such as GPS, Wi-Fi, etc. We may
                            collect, use, disclose and/or process this
                            information for one or more purposes including,
                            without limitation, location-based services that you
                            request or to deliver relevant content to you based
                            on your location or to allow you to share your
                            location to other users as part of our Services
                            under our mobile applications, in accordance with
                            this Privacy Policy. For most mobile devices, you
                            are able to withdraw your permission for us to
                            acquire your location information through your
                            device settings. If you have questions about how to
                            disable your mobile device’s location services,
                            please contact your mobile device service provider
                            or the device manufacturer.
                        </div>
                        <div className="fz24 fw500 ffab">4. COOKIES</div>
                        <div className="fz16">

                            4.1 We may from time to time implement “
                            <span className="fw500">cookies</span>” or
                            other features to allow us or third parties to
                            collect or share information that will help us
                            improve our Services or help us offer new services
                            and features. Cookies are identifiers we transfer to
                            your computer or mobile device that allow us to
                            recognize your computer or device and tell us how
                            and when our Services are used or visited, by how
                            many people and to track activity within our
                            Services. We may link cookie information to your
                            personal data. Cookies also link to information
                            regarding items you have selected for purchase,
                            pages you have viewed, and games you have played.
                        </div>
                        <div className="fz16">

                            4.2 You may refuse the use of cookies by selecting
                            the appropriate settings on your browser. However,
                            please note that by restricting or disabling
                            cookies, you may not be able to access the full
                            functionality of our Services.
                        </div>
                        <div className="fz24 fw500 ffab">
                            5. USE OF INFORMATION
                        </div>
                        <div className="fz16">

                            5.1 We may collect, use, disclose, store and/or
                            process your personal data for, but not limited to,
                            the following purposes (the “
                            <span className="fw500">
                                Purposes
                            </span>”):
                        </div>
                        <div className="fz16">

                            5.1.1 to consider and/or process your applications
                            or transactions with us or your transactions or
                            communications with third parties via the Services;
                        </div>
                        <div className="fz16">

                            5.1.2 to allow you to complete the registration of
                            your account for our Services as well as to log into
                            such account and access our Services;
                        </div>
                        <div className="fz16">

                            5.1.3 to manage, operate, provide and/or administer
                            your use of and/or access to our Services
                            (including, without limitation, your preferences),
                            as well as your relationship and user account with
                            us;
                        </div>
                        <div className="fz16">

                            5.1.4 to respond to, process, deal with or complete
                            a transaction and/or to fulfil your requests for
                            certain products and services and notify you of
                            service issues and unusual account actions;
                        </div>
                        <div className="fz16">

                            5.1.5 to enforce our Terms of Service or any
                            applicable end user license agreements;
                        </div>
                        <div className="fz16">

                            5.1.6 to protect personal safety and the rights,
                            property or safety of others and ourselves;
                        </div>
                        <div className="fz16">

                            5.1.7 for identification and/or verification;
                        </div>
                        <div className="fz16">

                            5.1.8 to enable you to use interactive features on
                            our Services including, but not limited to, using
                            voice and video to interact with other users of our
                            Services;
                        </div>
                        <div className="fz16">

                            5.1.9 to maintain and administer any software
                            updates and/or other updates and support that may be
                            required from time to time to ensure the smooth
                            running of our Services;
                        </div>
                        <div className="fz16">

                            5.1.10 to deal with or facilitate customer service,
                            carry out your instructions, deal with or respond to
                            any enquiries given by (or purported to be given by)
                            you or on your behalf;
                        </div>
                        <div className="fz16">

                            5.1.11 to contact you or communicate with you via
                            voice call, text message and/or fax message, email
                            and/or postal mail or otherwise for the purposes of
                            administering and/or managing your relationship with
                            us or your use of our Services, such as but not
                            limited to communicating and administrative
                            information to you relating to our Services. You
                            acknowledge and agree that such communication by us
                            could be by way of the mailing of correspondence,
                            documents or notices to you, which could involve
                            disclosure of certain personal data about you to
                            bring about delivery of the same as well as on the
                            external cover of envelopes/mail packages;
                        </div>
                        <div className="fz16">

                            5.1.12 to allow other users to interact or connect
                            with you, including to inform you when another user
                            has sent you a private message or posted a comment
                            for you on our Services;
                        </div>
                        <div className="fz16">

                            5.1.13 to conduct research, analysis and development
                            activities (including, but not limited to, data
                            analytics, surveys, product and service development
                            and/or profiling), to analyse how you use our
                            Services, to improve our Services or products and/or
                            to enhance your experience;
                        </div>
                        <div className="fz16">

                            5.1.14 to protect your gaming account and to create
                            a fair, healthy and safe gaming environment for the
                            users of our Services;
                        </div>
                        <div className="fz16">

                            5.1.15 to allow for advertising and other audits and
                            surveys to, among other things, validate the size
                            and composition of our target audience, and
                            understand their experience with our Services;
                        </div>
                        <div className="fz16">

                            5.1.16 for marketing and, in this regard, to send
                            you by various modes of communication marketing and
                            promotional information and materials relating to
                            products and/or services (including, without
                            limitation, products and/or services of third
                            parties whom we may collaborate or tie up with) that
                            we (and/or our affiliates or related corporations)
                            may be selling, marketing or promoting, whether such
                            products or services exist now or are created in the
                            future. You can unsubscribe from receiving marketing
                            information at any time by using the unsubscribe
                            function within the electronic marketing material.
                            We may use your contact information to send
                            newsletters from us and from our related companies;
                        </div>
                        <div className="fz16">

                            5.1.17 to respond to legal processes or to comply
                            with or as required by any applicable law,
                            governmental or regulatory requirements of any
                            relevant jurisdiction, including, without
                            limitation, meeting the requirements to make
                            disclosure under the requirements of any law binding
                            on us or our related corporations or affiliates;
                        </div>
                        <div className="fz16">

                            5.1.18 to produce statistics and research for
                            internal and statutory reporting and/or
                            record-keeping requirements;
                        </div>
                        <div className="fz16">

                            5.1.19 to carry out due diligence or other screening
                            activities (including, without limitation,
                            background checks) in accordance with legal or
                            regulatory obligations or our risk management
                            procedures that may be required by law or that may
                            have been put in place by us;
                        </div>
                        <div className="fz16">

                            5.1.20 to audit our Services or business;
                        </div>
                        <div className="fz16">

                            5.1.21 to prevent or investigate any actual or
                            suspected violations of our Terms of Service, fraud,
                            unlawful activity, omission or misconduct, whether
                            relating to your use of our Services or any other
                            matter arising from your relationship with us;
                        </div>
                        <div className="fz16">

                            5.1.22 to store, host, back up (whether for disaster
                            recovery or otherwise) of your personal data,
                            whether within or outside of your jurisdiction;
                        </div>
                        <div className="fz16">

                            5.1.23 to deal with and/or facilitate a business
                            asset transaction or a potential business asset
                            transaction, where such transaction involves us as a
                            participant or involves only our related corporation
                            or affiliate as a participant or involves us and/or
                            any one or more of our related corporations or
                            affiliates as participant(s), and there may be other
                            third party organisations who are participants in
                            such transaction. A “
                            <span className="fw500">
                                business asset transaction
                            </span>
                            ” refers to the purchase, sale, lease, merger,
                            amalgamation or any other acquisition, disposal or
                            financing of an organisation or a portion of an
                            organisation or of any of the business or assets of
                            an organisation;
                        </div>
                        <div className="fz16">

                            5.1.24 any other purposes which we notify you of at
                            the time of obtaining your consent; and/or
                        </div>
                        <div className="fz16">

                            5.1.25 any other purposes that may be relevant or
                            necessary to the provision of our Services to you.
                        </div>
                        <div className="fz16">

                            5.2 You acknowledge, consent and agree that we may
                            access, preserve and disclose your personal data,
                            including your account information, contents and
                            activities if required to do so by law or pursuant
                            to any order by a court or governmental or
                            regulatory authority of competent jurisdiction over
                            us or our Services or in a good faith belief that
                            such access preservation or disclosure is reasonably
                            necessary to: (a) comply with legal process; (b)
                            comply with a request from any governmental or
                            regulatory authority of competent jurisdiction, (c)
                            enforce our Terms of Service or this Privacy Policy;
                            (d) respond to any threatened or actual claims
                            asserted against us or other claim that any of your
                            content on our Services violates the rights of third
                            parties; (e) respond to your requests for customer
                            service; (f) protect the rights, property or
                            personal safety of our users, the public and
                            ourselves; and/or (g) achieve any of the Purposes.
                        </div>
                        <div className="fz16">

                            5.3 As the purposes for which we may collect, use,
                            disclose or process your personal data depend on the
                            circumstances at hand, such purpose may not appear
                            above. However, we will notify you of such other
                            purpose at the time of obtaining your consent,
                            unless processing of the applicable data without
                            your consent is permitted under the PDPA.
                        </div>
                        <div className="fz24 fw500 ffab">
                            6. PROTECTION OF PERSONAL DATA
                        </div>
                        <div className="fz16">

                            6.1 We take the security of your personal data very
                            seriously and will endeavour to take reasonable
                            security measures (both technical and
                            administrative) to protect your personal data from
                            misuse or unauthorised access, public disclosure,
                            use, modification, damage, loss, or leak.
                        </div>
                        <div className="fz16">

                            6.2 We will use reasonably practicable means to
                            protect your personal data, such as using encryption
                            technology and anonymisation, no less than that of
                            our industry peers, and use security mechanisms to
                            protect your personal information from malicious
                            attacks.
                        </div>
                        <div className="fz16">

                            6.3 We may establish a dedicated security
                            department, security management system and data
                            security procedures to protect your personal
                            information. We adopt strict data usage and access
                            systems to ensure that only authorised personnel
                            have access to your personal information and conduct
                            timely security audits of data and technology.
                        </div>
                        <div className="fz16">

                            6.4 Although we may take reasonable and effective
                            measures as described above and comply with the
                            standards required by the relevant laws, please
                            understand that due to technical limitations and the
                            possibility of various malicious activities, it is
                            not always possible to guarantee complete security
                            of your personal in the Internet industry, even with
                            all efforts to enhance security measures, and we
                            will do our best to ensure the security of the
                            personal data you provide to us. You are aware and
                            understand that the systems and communication
                            networks you use to access our services may have
                            weaknesses due to factors outside of our control.
                            Therefore, we strongly recommend that you take
                            proactive measures to protect the security of your
                            personal data, including but not limited to, using
                            complex passwords, changing your password regularly,
                            and not disclosing your personal data, such as your
                            account password, to other parties.
                        </div>
                        <div className="fz24 fw500 ffab">
                            7. RETENTION OF PERSONAL DATA
                        </div>
                        <div className="fz16">

                            7.1 We will retain personal in accordance with the
                            PDPA and other applicable laws. That is, we will
                            destroy or anonymize your personal data when we have
                            reasonably determined that (i) the purpose for which
                            that personal data was collected is no longer being
                            served by the retention of such personal data; (ii)
                            retention is no longer necessary for any legal or
                            business purposes; and (iii) no other legitimate
                            interest warrant further retention of such personal
                            data. If you cease using our Services, or your
                            access to our Services is terminated or withdrawn,
                            we may continue storing, using and/or disclosing
                            your personal data in accordance with this Privacy
                            Policy and our obligations under the PDPA. Subject
                            to applicable law, we may securely dispose of your
                            personal data without prior notice to you.
                        </div>
                        <div className="fz24 fw500 ffab">
                            8. DISCLOSURE TO THIRD PARTIES
                        </div>
                        <div className="fz16">

                            8.1 In conducting our business, we may disclose your
                            personal data to third parties such as our service
                            providers, agents and/or our affiliates or related
                            corporations, and/or other third parties, whether
                            situated in Singapore or outside of Singapore, for
                            one or more of the Purposes and/or the purposes
                            identified in Clause 8.2. Such third-party service
                            providers, agents and/or affiliates or related
                            corporations and/or other third parties would be
                            processing your personal data either on our behalf
                            or otherwise, for one or more of the Purposes. Such
                            third parties include, without limitation:
                        </div>
                        <div className="fz16">

                            8.1.1 our subsidiaries, affiliates and related
                            corporations;
                        </div>
                        <div className="fz16">

                            8.1.2 other users of our Services;
                        </div>
                        <div className="fz16">

                            8.1.3 contractors, agents, service providers and
                            other third parties we use to support our business
                            or our Services. These include but are not limited
                            to those which provide administrative or other
                            services to us such as mailing houses,
                            telecommunication companies, information technology
                            companies and data centres;
                        </div>
                        <div className="fz16">

                            8.1.4 a buyer or other successor in the event of a
                            merger, divestiture, restructuring, reorganization,
                            dissolution or other sale or transfer of some or all
                            of our assets, whether as a going concern or as part
                            of bankruptcy, liquidation or similar proceeding, in
                            which personal data held by us about our users is
                            among the assets transferred; or to a counterparty
                            in a business asset transaction that we or any of
                            our affiliates or related corporations are involved
                            in; and
                        </div>
                        <div className="fz16">

                            8.1.5 third parties to whom disclosure by us is for
                            one or more of the Purposes and such third parties
                            would in turn be collecting and processing your
                            personal data for one or more of the Purposes.
                        </div>
                        <div className="fz16">

                            8.2 Some of the purposes, in addition to the
                            Purposes, for disclosing personal data to
                            third-parties may include, without limitation:
                        </div>
                        <div className="fz16">

                            8.2.1 to ensure consistency in the services you
                            receive between our Services and those of our
                            affiliates, and to facilitate the consistent
                            management of your information;
                        </div>
                        <div className="fz16">

                            8.2.2 to enable programmatic ad delivery;
                        </div>
                        <div className="fz16">

                            8.2.3 to help them increase the effectiveness of
                            advertising without identifying you personally;
                        </div>
                        <div className="fz16">

                            8.2.4 to fulfil our business with third parties for
                            the purpose of aggregating statistics and analysing
                            features and preferences through algorithms to form
                            indirect demographic profiles that can be used to
                            make recommendations to you, to display or deliver
                            information that may be of interest to you, or to
                            deliver specific features, services or commercials
                            that are more suitable for you;
                        </div>
                        <div className="fz16">

                            8.2.5 to ensure that you receive a consistent
                            experience at joint events, or to engage third
                            parties to redeem rewards to you in a timely manner.
                        </div>
                        <div className="fz16">

                            8.3 This may require, among other things, disclosing
                            your gaming history and/or disclosing records
                            showing which games were viewed by which IP
                            addresses. We will also share statistical and
                            demographic information about our users and their
                            use of the Services with suppliers of advertisements
                            and programming.
                        </div>
                        <div className="fz16">

                            8.4 Additionally, you may elect to export your user
                            activity feeds as RSS feeds which may be subscribed
                            to by other users, which may include information
                            regarding your game play, installation of games,
                            connections with other users and other information
                            about your account activity which is published as a
                            feed on our Services.
                        </div>
                        <div className="fz16">

                            8.5 For the avoidance of doubt, in the event that
                            the PDPA or other applicable laws permit us to
                            collect, use or disclose your personal data without
                            your consent, such permission granted by the laws
                            shall continue to apply.
                        </div>
                        <div className="fz16">

                            8.6 Third parties may unlawfully intercept or access
                            personal data transmitted to or contained on our
                            Services, technologies may malfunction or not work
                            as anticipated, or someone might access, abuse or
                            misuse information through no fault of ours. We will
                            nevertheless deploy reasonable security arrangements
                            to protect your personal data as required by the
                            PDPA; however there can inevitably be no guarantee
                            of absolute security such as but not limited to when
                            unauthorised disclosure arises from malicious and
                            sophisticated hacking by malcontents through no
                            fault of ours.
                        </div>
                        <div className="fz24 fw500 ffab">
                            9. INFORMATION ON CHILDREN
                        </div>
                        <div className="fz16">

                            9.1 The Services are not intended for children under
                            the age of 18. We do not knowingly collect or
                            maintain any personal data or non-personally
                            identifiable information from anyone under the age
                            of 18 nor are our Services directed to children
                            under the age of 18. As a parent or legal guardian,
                            please do not allow children under your care to
                            submit personal data to us. In the event that
                            personal data of a child under the age of 18 in your
                            care is disclosed to us, you hereby consent to the
                            collection, use, storage and/or processing of such
                            child’s personal data and accept and agree to be
                            bound by this Privacy Policy on behalf of such
                            child. We will endeavour to close any accounts used
                            exclusively by such children and remove and/or
                            delete any personal data we believe was submitted
                            without parental consent by any child under the age
                            of 18.
                        </div>
                        <div className="fz24 fw500 ffab">
                            10. INFORMATION COLLECTED BY THIRD PARTIES
                        </div>
                        <div className="fz16">

                            10.1 Our Site and Games use Google Analytics, a web
                            analytics service provided by Google, Inc.
                            (“Google”). Google Analytics uses cookies, which are
                            text files placed on your device, to help the
                            website analyse how users use our Services. The
                            information generated by the cookie about your use
                            of the Services or device (including your IP
                            address) will be transmitted to and stored by Google
                            on servers in the United States. Google will use
                            this information for the purpose of evaluating your
                            use of our Services, compiling reports on website
                            activity for website operators and providing other
                            services relating to website activity and Internet
                            usage. Google may also transfer this information to
                            third parties where required to do so by law, or
                            where such third parties process the information on
                            Google’s behalf. Google will not associate your IP
                            address with any other data held by Google.
                        </div>
                        <div className="fz16">

                            10.2 We, and third parties, may from time to time
                            make software applications downloads available for
                            your use on or through the Services. These
                            applications may separately access, and allow a
                            third party to view your personal data, such as your
                            name, your user ID, your device’s IP address or
                            other information such as what game you are playing
                            during any given session, and any cookies that you
                            may previously have installed or that were installed
                            for you by a third party software application or
                            website. Additionally, these applications may ask
                            you to provide additional information directly to
                            third parties. Third party products or services
                            provided through these applications are not owned or
                            controlled by us. You are encouraged to read the
                            terms and privacy policies published by such third
                            parties on their websites or otherwise.
                        </div>
                        <div className="fz24 fw500 ffab">
                            11. DISCLAIMER REGARDING SECURITY AND THIRD-PARTY
                            SITES
                        </div>
                        <div className="fz16">

                            11.1 Our Services may contain links to other
                            websites. This Privacy Policy is only applicable to
                            our Services. Hence, should you be linked to other
                            websites, you should read those websites’ privacy
                            policies. You acknowledge that we shall not be
                            liable for your use or any websites outside our
                            Services.
                        </div>
                        <div className="fz24 fw500 ffab">
                            12. TRANSBORDER DATA FLOWS
                        </div>
                        <div className="fz16">

                            12.1 In using our Services, you understand,
                            acknowledge and consent to the transfer of your
                            personal data out of Singapore where such transfer
                            is necessary for the provision of access to our
                            Services or any of the Purposes.
                        </div>
                        <div className="fz16">

                            12.2 Where your personal data is transferred out of
                            Singapore, we will take the appropriate steps to
                            ensure that the standard of protection accorded to
                            your personal data transferred abroad is comparable
                            to the protection that is accorded to it under the
                            PDPA.
                        </div>
                        <div className="fz24 fw500 ffab">
                            13. WITHDRAWAL OF CONSENT, REQUEST OF ACCESS TO AND
                            CORRECTION OF INFORMATION PROVIDED
                        </div>
                        <div className="fz16">

                            13.1 You may withdraw, at any time, your consent for
                            the collection, use and/or disclosure of your
                            personal data in our possession or under our control
                            by sending an email to our Data Protection Officer
                            (our “ <span className="fw500">DPO</span>”)
                            at contact@TAP-FUN.com.sg and we will process such
                            requests in accordance with this Privacy Policy and
                            our obligations under the PDPA and any other
                            applicable law.
                        </div>
                        <div className="fz16">

                            13.2 You acknowledge that withdrawing your consent
                            in accordance with Clause 13.1 above, may affect
                            your user experience on our Services mean or even
                            lead to the cessation of your use of our Services,
                            especially where provision of access to our Services
                            is reliant on the collection, use or disclosure of
                            your personal data.
                        </div>
                        <div className="fz24 fw500 ffab">
                            14. ACCESS RIGHTS AND CORRECTION OR UPDATE OF
                            PERSONAL DATA
                        </div>
                        <div className="fz16">

                            14.1 It is of utmost importance to use that your
                            personal data is accurate and up to date. If any of
                            the information that you have provided us has
                            changed, please inform or update our DPO at
                            contact@TAP-FUN.com.sg or if you have an account with
                            us, you may personally correct your personal data
                            currently in our possession or control through the
                            Account Settings page or tab on the Site or our
                            Games.
                        </div>
                        <div className="fz16">

                            14.2 Subject to the exceptions referred to in
                            Section 21(2) of the PDPA, you have the right to
                            request from us information regarding your personal
                            data in our possession or control and the manner in
                            which your personal data had been used or disclosed
                            within a year prior to the request.
                        </div>
                        <div className="fz16">

                            14.3 We may charge you a reasonable fee for the
                            handling and processing of your requests to access
                            your personal data. If we so choose to charge, we
                            will provide you with a written estimate of the fee
                            we will be charging. Please note that we are not
                            required to respond to or deal with your access
                            request unless you have agreed to pay the fee.
                        </div>
                        <div className="fz24 fw500 ffab">15. DATA BREACH</div>
                        <div className="fz16">

                            15.1 In the event of a data breach in which the
                            confidentiality of your personal data was
                            compromised, we will notify you through the contact
                            information provided by you, to the extent required
                            by the PDPA.
                        </div>
                        <div className="fz24 fw500 ffab">16. CONTACT US</div>
                        <div className="fz16">

                            16.1 If you have any questions, concerns or
                            complaints about our privacy practices, please
                            contact our DPO at contact@TAP-FUN.com.sg.
                        </div>
                        <div className="fz24 fw500 ffab">17. How to Delete</div>
                        <div className="fz16">

                            17.1 You have the right to request us delete the
                            personal information that we collected from you and
                            retain, subject to certain exceptions. Once we
                            receive and confirm your verifiable consumer
                            request, we will delete (and direct our service
                            providers to delete) your personal information from
                            our records, unless an exception applies. If you
                            want to delete your account or infotmation, please
                            contact us by this email: support@TAP-FUN.com
                        </div>
                        <div className="fz24 fw500 ffab">
                            18. GOVERNING LAW AND DISPUTE RESOLUTION
                        </div>
                        <div className="fz16">

                            18.1 This Privacy Policy and your use of our
                            Services shall be governed in all respects by the
                            laws of Singapore. Any dispute arising out of or in
                            connection with this Privacy Policy, including any
                            question regarding its existence, validity or
                            termination, shall be referred to and finally
                            resolved by arbitration administered by the
                            Singapore International Arbitration Centre (“
                            <span className="fw500">SIAC</span> ”) in
                            accordance with the Arbitration Rules of the
                            Singapore International Arbitration Centre (“
                            <span className="fw500">SIAC Rules</span>
                            “) for the time being in force, which rules are
                            deemed to be incorporated by reference in this
                            clause. The seat of the arbitration shall be
                            Singapore. The Tribunal shall consist of one
                            arbitrator. The language of the arbitration shall be
                            English.
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default Page;
