import React,{useEffect,useState} from 'react'
import config from '@/utils/config'
import Style from './Header.module.scss'
import classNames from 'classnames'
import { useLocation,useHistory } from 'react-router-dom'


const Header=(props)=>{
    const [checkIndex,setCheckIndex]=useState(null)
    let location=useLocation();
    let history=useHistory();
    const links=[
        {title:'GAME',id:'game'},
        {title:'ABOUT',id:'about'},
        {title:'CONTACT',id:'contact'}
    ]
    useEffect(() => {
       if(location.state && location.state.id){
           // 找到锚点
           let anchorElement = document.getElementById(location.state.id);
           // 如果对应id的锚点存在，就跳转到锚点
           if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
       }
    }, []);

    useEffect(()=>{
        setCheckIndex(props.checkIndex)
    },[props.checkIndex])

    const jumpTo=(anchorName)=>{
        if(location.pathname != '/'){
            history.push({
                pathname:'/',
                state:{
                    id:anchorName
                }
            })
            let index=links.findIndex(item=>item.id == anchorName)
            setCheckIndex(index)
            return;
        }
        let index=links.findIndex(item=>item.id == anchorName)
            setCheckIndex(index)
          // 找到锚点
          let anchorElement = document.getElementById(anchorName);
          // 如果对应id的锚点存在，就跳转到锚点
          if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
    }

    return <div className={classNames('fixed left0 top0 header-top pt22 pb22 flex items-center full-width pl46 pr46 sizing',[Style.header])}>
        <div className="logo">
            <img src={require('@/assets/images/logo.png').default} alt="" />
        </div>
        <div className={[Style.links]}>
            {
                links.map((link,linkIndex)=>{
                    return <div onClick={()=>jumpTo(link.id)} className={classNames('link-item hover-primary ffab',{ 'text-primary' : checkIndex == linkIndex})}  key={link.title} >{link.title}</div>
                })
            }
        </div>
    </div>
}

export default Header;
