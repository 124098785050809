import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import {useState,useEffect} from 'react'
import Index from "@/views/index.jsx";
import Privacy from "@/views/privacy";
import TermsOfService from "@/views/termsOfService";
import Reply from '@/views/reply'
function App() {
    const [showBackTop, setshowBackTop] = useState(false);
    const getScrollTop=()=>{
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }
    const handleScroll=()=>{
        let scrollTop=getScrollTop();
        let windowHeight=window.innerHeight;
        if(scrollTop > windowHeight){
            setshowBackTop(true)
        }else{
            setshowBackTop(false)
        }
    }
    useEffect(()=>{
        window.addEventListener('scroll',handleScroll,false)
        return ()=>{
            window.removeEventListener('scroll',handleScroll,false)
        }
    },[])

    const  scrollTop=(el, from = 0, to, duration = 500, endCallback)=> {
        if (!window.requestAnimationFrame) {
            window.requestAnimationFrame = (
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback) {
                    return window.setTimeout(callback, 1000/60);
                }
            );
        }
        const difference = Math.abs(from - to);
        const step = Math.ceil(difference / duration * 50);

        function scroll(start, end, step) {
            if (start === end) {
                endCallback && endCallback();
                return;
            }

            let d = (start + step > end) ? end : start + step;
            if (start > end) {
                d = (start - step < end) ? end : start - step;
            }

            if (el === window) {
                window.scrollTo(d, d);
            } else {
                el.scrollTop = d;
            }
            window.requestAnimationFrame(() => scroll(d, end, step));
        }
        scroll(from, to, step);
    }
    const BackTop=()=>{
        const sTop = getScrollTop();
        scrollTop(window, sTop, 0, 500);
    }

    return (
        <>
            <Router>
                <Switch>
                    <Route component={Index} path="/" exact></Route>
                    <Route
                        component={Privacy}
                        path="/privacyPolicy"
                        exact
                    ></Route>
                    <Route
                        component={TermsOfService}
                        path="/termsAndConditions"
                        exact
                    ></Route>
                    <Route component={Reply} path="/reply" exact></Route>
                    <Route render={() => <div>Not Found</div>} />
                </Switch>
            </Router>
            {showBackTop ? (
                <div className="backTop" onClick={()=>BackTop()}>
                    <img
                        src={require("@/assets/images/backTop.png").default}
                        alt=""
                    />
                </div>
            ) : null}
        </>
    );
}
export default App;
